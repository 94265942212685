/* eslint-disable no-bitwise */
import Cookies from "js-cookie";
import moment from "moment";

export const readCookie = () => {
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refreshToken");
  const sessionTime: any = Cookies.get("sessionTime");

  return [token || "", refreshToken || "", sessionTime || ""];
};

export const setCookie = (
  token: string,
  refreshToken: string,
  sessionTime?: string | number | any,
) => {
  Cookies.set("token", token);
  Cookies.set("refreshToken", refreshToken);
  if (sessionTime) {
    Cookies.set("sessionTime", sessionTime);
  }
};

export const clearStorage = () => {
  Cookies.remove("token");
  Cookies.remove("refreshToken");
  Cookies.remove("sessionTime");
};

export const getToken = () => {
  const token = Cookies.get("token");
  return token ?? "";
};

export const jsonToURI = (json: object) => encodeURIComponent(JSON.stringify(json));

export const uriToJSON = (uri: string) => JSON.parse(decodeURIComponent(uri));

export const getTariffName = (gridData: any, id: number): Record<string, any> =>
  gridData?.find((data: any) => data.id === id);

export const getBrandName = (listData: any, brand: string): Record<string, any> =>
  listData?.find((data: any) => data.value === brand);

export const getCountry = (countries: any, id: number): Record<string, any> =>
  countries?.find((country: any) => country.id === id);

export const getStatus = (ticketStatus: any, status: number): Record<string, any> =>
  ticketStatus?.find((statu: any) => statu.value === status);

export const getCountryName = (countries: any, name: string): Record<string, any> =>
  countries?.find((country: any) => country.name.toLowerCase() === name.toLowerCase());

export const getState = (countries: any, id: number): Record<string, any> => {
  let stateObj = {};
  /* eslint array-callback-return: "off" */
  countries?.find((country: any) => {
    /* eslint array-callback-return: "off" */
    country?.states?.find((state: any) => {
      if (state.gid === id) {
        stateObj = state;
      }
    });
  });
  return stateObj;
};

export const truncate = (str: string, length: number) => {
  const truncatedStr = str.substring(0, length);
  return `${truncatedStr}...`;
};

export const getStateName = (countries: any, name: string): Record<string, any> => {
  let stateObj = {};
  /* eslint array-callback-return: "off" */
  countries?.find((country: any) => {
    /* eslint array-callback-return: "off" */
    country?.states?.find((state: any) => {
      if (state.name === name) {
        stateObj = state;
      }
    });
  });
  return stateObj;
};

export const getVehicleTypeObj = (vehicleTypes: any[], value: string): Record<string, any> =>
  vehicleTypes?.find((vehicleType: any) => vehicleType.value === value);

export const getPlugTypeObj = (plugTypes: any[], value: string): Record<string, any> =>
  plugTypes?.find((plugType: any) => plugType.value === value);

export const removeEmptyObjProperties = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });
  return obj;
};

export const dateFormatter = (date: string) => moment(date).format("DD/MM/YYYY hh:mm A");

export const secondsToHoursMinutes = (seconds: number) => {
  const hours = Math.floor(seconds / 3600); // 1 hour has 3600 seconds
  let remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60); // 1 minute has 60 seconds

  remainingSeconds = seconds % 60;

  return {
    hours,
    minutes,
    seconds: remainingSeconds?.toFixed(0),
  };
};

export const findMinMaxValuesWithIndices = (array = []) => {
  let minValue = Infinity;
  let minIndex = -1;
  let maxValue = -Infinity;
  let maxIndex = -1;
  let sum = 0;

  for (let i = 0; i < array.length; i++) {
    if (array[i] < minValue) {
      minValue = array[i];
      minIndex = i;
    }

    if (array[i] > maxValue) {
      maxValue = array[i];
      maxIndex = i;
    }

    sum += array[i];
  }

  const average = array.length > 0 ? sum / array.length : 0;

  return {
    minValue: { value: minValue, index: minIndex + 1 },
    maxValue: { value: maxValue, index: maxIndex + 1 },
    average: average.toFixed(2),
  };
};

export const DynamicIndex = (series: any) => {
  let dynamicIndex = 0;
  if (series[0]?.length > 0) {
    dynamicIndex = 0;
  } else if (series[1]?.length > 0) {
    dynamicIndex = 1;
  } else if (series[2]?.length > 0) {
    dynamicIndex = 2;
  }
  return dynamicIndex;
};

// eslint-disable-next-line no-promise-executor-return
export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Function to retrieve a value from an object based on a given path
export const getValueFromPath = (mainObj: Record<string, any>, path: string) => {
  if (mainObj) {
    const obj = JSON.parse(JSON.stringify(mainObj));
    // Check if both obj and path are provided
    if (obj && path) {
      try {
        // Split the path into keys
        const keys = path.split(".");
        let tempObj = obj;

        // Loop through each key in the path
        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys) {
          // If tempObj is null, undefined, or not an object, return 0
          if (!tempObj || typeof tempObj !== "object") {
            return 0;
          }
          // Move to the next nested object using the current key
          tempObj = tempObj[key];
        }

        // Return the value found at the end of the path
        return tempObj;
      } catch (error) {
        // Catch any errors that occur and return 0
        return 0;
      }
    } else {
      // If either obj or path is not provided, return 0
      return 0;
    }
  }
  return 0;
};

// This function takes a sum of bits and separates it into individual bit positions that are set to 1.
export const separateBits = (sumOfBits: number) => {
  let tempSumOfBits = sumOfBits; // Store the input sumOfBits in a temporary variable
  const result = []; // Initialize an empty array to store individual bit positions
  let currentBit = 1; // Start with the least significant bit position (1)

  // Loop until all bits in tempSumOfBits are processed
  while (tempSumOfBits > 0) {
    // Check if the current least significant bit is set to 1
    if (tempSumOfBits & 1) {
      result.push(currentBit); // If true, push the current bit position to the result array
    }
    tempSumOfBits >>= 1; // Right shift tempSumOfBits to process the next bit position
    currentBit <<= 1; // Left shift currentBit to move to the next bit position
  }

  return result; // Return the array containing individual bit positions where bits are set to 1
};

export const Checkpermission = (path: any, action: any, permissions: any) => {
  const bitValue = getValueFromPath(permissions || {}, path);
  const isCreatePermission = separateBits(+bitValue);
  const isDisable = isCreatePermission.includes(action);
  return isDisable;
};

export const censorEmail = (email: string, keepChars = 2) => {
  // Split the email into local part and domain part
  const [localPart, domainPart] = email.split("@");

  // If the local part is shorter than or equal to keepChars, return it as is
  if (localPart.length <= keepChars) {
    return email; // Do not censor if too short
  }

  // Get the first `keepChars` characters and calculate how many to replace
  const firstPart = localPart.slice(0, keepChars);
  const remaining = localPart.slice(keepChars);

  // Replace the remaining characters with asterisks
  const censoredLocalPart = firstPart + "*".repeat(remaining.length);

  // Return the censored email
  return `${censoredLocalPart}@${domainPart}`;
};

export const censorPhone = (phone: string, keepDigits = 4) => {
  // Normalize the phone number by removing non-digit characters
  const normalizedPhone = phone.replace(/\D/g, "");

  // Validate phone number length (considering typical lengths)
  if (normalizedPhone.length < keepDigits) {
    throw new Error("Invalid phone number format");
  }

  // Get the last `keepDigits` characters
  const visiblePart = normalizedPhone.slice(-keepDigits);
  const hiddenPart = normalizedPhone.slice(0, -keepDigits);

  // Replace hidden part with asterisks
  const censoredPhone = "*".repeat(hiddenPart.length) + visiblePart;

  return censoredPhone;
};
